export default {
    sketchfab__item : {
        '.sketchfab-embed-wrapper' : {
            paddingTop: '56.25%',
            position: 'relative',
            overflow : 'hidden',
            'iframe' : {
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                left: '0px'
            }
        }
    }   
}