/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./sketchfabItemStyle";
import utils from "utils";
import cmsUtils from "../../cmsUtils";

export function SketchfabItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "sketchfab__item",
    item.cssClass || item.anchorName || ""
  );
  const code = cmsUtils.payload(item, "Code") || "";

  if (!code || code === "") {
    return (
      <div
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <pre
          style={{
            fontSize: "12px",
            margin: "5px",
            padding: "10px",
            background: "#eee",
            border: "1px solid #ccc",
          }}
        >
          <b> Please add code </b>
          {code}
        </pre>
      </div>
    );
  }

  return (
    <div
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
      dangerouslySetInnerHTML={{ __html: code }}
      css = {style.sketchfab__item}
    >
    </div>
  );
}