import { css } from "@emotion/core";
import { mq } from "../../cssInJs";

export default {
  top: css(
    mq({
      //height: [44, null, 80],
      height: [44, null, 56],
      background: "black",
      position: "fixed",
      width: "100%",
      zIndex: "1100",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: "all 0.3s", 
      // top: [0, 0, 0, "60px"],
    })
  ),
  topScrolled: css(
    mq({
      //height: [44, null, 56],
      // background: "white",
    })
  ),
  logo: css(mq({})),
  logoImg: css(
    mq({
      display: "block",
      position: "absolute",
      left: 20,
      top: 10,
      height: [30, null, 40],
      zIndex: 1000
      // marginTop: ["3rem", null, "3.5rem"],
      // transition: "all .2s",
    })
  ),
  logoScrolled: css(
    mq({
      img: css(
        mq({
          // height: [38, null, 50],
          // marginTop: "0rem",
        })
      ),
    })
  ),
};
