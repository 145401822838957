import React from "react";
import { SiteLink } from "../../components";

export function GlobalNav(props) {
  return (
    <>
      <div id="globalNav">
        <div className="globalNav_top">
          <div className="globalNav_top_content">
            <div className="globalNav_main">
              <a href="/products">PRODUCTS & PRICING</a>
              <div className="globalNav_sub">
                <div className="globalNav_sub_products">
                  <a href="/products/wall-mounted-whole-house-water-filtration-system">
                    <div>
                      <img
                        src="/assets/Wall_Mounted_Top_Nav.jpg"
                        width="100%"
                        alt = "Wall_Mounted_Top_Nav"
                      />
                    </div>
                    Wall Mounted System
                  </a>
                  <a href="/products/above-ground-whole-house-water-filtration-system">
                    <div>
                      <img
                        src="/assets/Above_Ground_Top_Nav3.jpg"
                        width="100%"
                        alt = "Above_Ground_Top_Nav"
                      />
                    </div>
                    Above Ground System
                  </a>
                  <a href="/products/below-ground-whole-house-water-filtration-system">
                    <div>
                      <img
                        src="/assets/Below_Ground_Top_Nav.jpg"
                        width="100%"
                        alt = "Below_Ground_Top_Nav"
                      />
                    </div>
                    Below Ground System
                  </a>
                  <a href="/products/reverse-osmosis-under-sink-water-filtration-system">
                    <div>
                      <img
                        src="/assets/Reverse_Osmosis_Top_Nav_2.jpg"
                        width="100%"
                        alt = "Reverse_Osmosis_Top_Nav"
                      />
                    </div>
                    Reverse Osmosis
                  </a>
                </div>
              </div>
            </div>

            <div className="globalNav_main">
              <a href="/benefits">BENEFITS</a>
              <div className="globalNav_sub">
                <div className="globalNav_sub_benefits"></div>
              </div>
            </div>

            <div className="globalNav_main">
              <a href="/servicing">ANNUAL SERVICING</a>
              <div className="globalNav_sub">
                <div className="globalNav_sub_servicing"></div>
              </div>
            </div>
            <div className="globalNav_main">
              <a href="/reviews">REVIEWS</a>
              <div className="globalNav_sub">
                <div className="globalNav_sub_reviews"></div>
              </div>
            </div>

            <div className="globalNav_main">
              <a href="/faq">COMMON QUESTIONS</a>
              <div className="globalNav_sub">
                <div className="globalNav_sub_faq"></div>
              </div>
            </div>

            {/* <a className="button" href="/contact">
              <span>CONTACT</span>
            </a> */}

            <SiteLink to="#contact" className = 'button'>
              <span>CONTACT</span>
            </SiteLink>

          </div>
        </div>
      </div>
    </>
  );
}
